<template>
  <ContentLoader v-slot :loading="item == null">
    <Hero :image="item.image">
      <h1>
        {{ item.name }}
        <b-button variant="link" class="p-0" @click="toggleFavourite">
          <Icon :icon="isFavourite ? 'heart-fill' : 'heart'" />
        </b-button>
      </h1>
      <div class="d-flex flex-wrap align-items-center">
        <span class="text-nowrap">
          <strong>{{ item.albumCount }}</strong> albums
        </span>
        <span class="mx-1">•</span>
        <span class="text-nowrap mr-3">
          <router-link :to="{name: 'artist-tracks', params: { id }}"><strong>{{ item.trackCount }}</strong> tracks</router-link>
        </span>
        <div class="d-flex flex-nowrap">
          <ExternalLink v-if="item.lastFmUrl" :href="item.lastFmUrl" class="btn btn-link p-0 mr-2" title="Last.fm">
            <IconLastFm />
          </ExternalLink>
          <ExternalLink v-if="item.musicBrainzUrl" :href="item.musicBrainzUrl" class="btn btn-link mr-2 p-0" title="MusicBrainz">
            <IconMusicBrainz />
          </ExternalLink>
          <ExternalLink :href="`https://rateyourmusic.com/search?searchterm=${encodeURIComponent(item.name)}&searchtype=a`" class="btn btn-link mr-2 p-0" title="Rate Your Music">
            <IconRateYourMusic />
          </ExternalLink>
          <ExternalLink :href="`https://redacted.ch/artist.php?artistname=${encodeURIComponent(item.name)}`" class="btn btn-link mr-2 p-0" title="Redacted">
            <IconRED />
          </ExternalLink>
        </div>
      </div>
      <div v-if="item.genres.length > 0">
        <span v-for="({ name: genre }, index) in item.genres" :key="genre">
          <span v-if="index > 0">•</span>
          <router-link :to="{name: 'genre', params: { id: genre }}">
            {{ genre }}
          </router-link>
        </span>
      </div>

      <OverflowFade v-if="item.description" class="mt-3">
        {{ item.description }}
      </OverflowFade>

      <div class="text-nowrap mt-3">
        <b-button variant="secondary" :disabled="item.topTracks.length === 0" class="mr-2" @click="playNow">
          <Icon icon="play" /> Play
        </b-button>
        <b-button variant="secondary" :disabled="item.topTracks.length === 0" @click="shuffleNow">
          <Icon icon="shuffle" /> Shuffle
        </b-button>
      </div>
    </Hero>

    <template v-if="item.topTracks.length > 0">
      <div class="d-flex justify-content-between mt-5 mb-2">
        <h3 class="my-0">
          Top tracks
        </h3>
        <router-link :to="{name: 'artist-top-tracks', params: { id }}">
          View all
        </router-link>
      </div>
      <TrackList :tracks="item.topTracks" no-artist />
    </template>

    <template v-if="albumReleases.length > 0">
      <div class="d-flex justify-content-between mt-5 mb-2">
        <h3 class="my-0">
          Albums
        </h3>
        <b-button variant="link" class="p-0" @click="toggleAlbumSortOrder">
          <Icon icon="arrow-up-down" />
        </b-button>
      </div>
      <AlbumList :items="albumReleases" />
    </template>

    <template v-if="epReleases.length > 0">
      <div class="d-flex justify-content-between mt-5 mb-2">
        <h3 class="my-0">
          E.P.
        </h3>
      </div>
      <AlbumList :items="epReleases" />
    </template>

    <template v-if="liveReleases.length > 0">
      <div class="d-flex justify-content-between mt-5 mb-2">
        <h3 class="my-0">
          Live Albums
        </h3>
      </div>
      <AlbumList :items="liveReleases" />
    </template>

    <template v-if="soundtrackReleases.length > 0">
      <div class="d-flex justify-content-between mt-5 mb-2">
        <h3 class="my-0">
          Soundtracks
        </h3>
      </div>
      <AlbumList :items="soundtrackReleases" />
    </template>

    <template v-if="singleReleases.length > 0">
      <div class="d-flex justify-content-between mt-5 mb-2">
        <h3 class="my-0">
          Singles
        </h3>
      </div>
      <AlbumList :items="singleReleases" />
    </template>

    <template v-if="anthologyReleases.length > 0">
      <div class="d-flex justify-content-between mt-5 mb-2">
        <h3 class="my-0">
          Anthologies
        </h3>
      </div>
      <AlbumList :items="anthologyReleases" />
    </template>

    <template v-if="item.similarArtist.length > 0">
      <h3 class="mt-5">
        Similar artists
      </h3>
      <ArtistList :items="item.similarArtist" />
    </template>
  </ContentLoader>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import AlbumList from '@/library/album/AlbumList.vue'
  import ArtistList from '@/library/artist/ArtistList.vue'
  import TrackList from '@/library/track/TrackList.vue'
  import { useFavouriteStore } from '@/library/favourite/store'
  import OverflowFade from '@/shared/components/OverflowFade.vue'
  import { Album } from '@/shared/api'
  import { orderBy } from 'lodash-es'
  import { useMainStore } from '@/shared/store'
  import IconLastFm from '@/shared/components/IconLastFm.vue'
  import IconMusicBrainz from '@/shared/components/IconMusicBrainz.vue'
  import IconRateYourMusic from '@/shared/components/IconRateYourMusic.vue'
  import IconRED from '@/shared/components/IconRED.vue'

  export default defineComponent({
    components: {
      IconMusicBrainz,
      IconLastFm,
      IconRateYourMusic,
      IconRED,
      AlbumList,
      ArtistList,
      OverflowFade,
      TrackList,
    },
    props: {
      id: { type: String, required: true }
    },
    setup() {
      return {
        mainStore: useMainStore(),
        favouriteStore: useFavouriteStore()
      }
    },
    data() {
      return {
        item: null as any,
      }
    },
    computed: {
      isFavourite(): boolean {
        return !!this.favouriteStore.artists[this.id]
      },
      albums(): Album[] {
        return orderBy(this.item?.albums ?? [], 'year', this.mainStore.artistAlbumSortOrder)
      },
      albumReleases(): Album[] {
        return orderBy(this.item?.albumReleases ?? [], 'year', this.mainStore.artistAlbumSortOrder)
      },
      liveReleases(): Album[] {
        return orderBy(this.item?.liveReleases ?? [], 'year', this.mainStore.artistAlbumSortOrder)
      },
      epReleases(): Album[] {
        return orderBy(this.item?.epReleases ?? [], 'year', this.mainStore.artistAlbumSortOrder)
      },
      soundtrackReleases(): Album[] {
        return orderBy(this.item?.soundtrackReleases ?? [], 'year', this.mainStore.artistAlbumSortOrder)
      },
      anthologyReleases(): Album[] {
        return orderBy(this.item?.anthologyReleases ?? [], 'year', this.mainStore.artistAlbumSortOrder)
      },
      singleReleases(): Album[] {
        return orderBy(this.item?.singleReleases ?? [], 'year', this.mainStore.artistAlbumSortOrder)
      },
    },
    watch: {
      id: {
        immediate: true,
        async handler(value: string) {
          this.item = null
          this.item = await this.$api.getArtistDetails(value)
        }
      }
    },
    methods: {
      playNow() {
        return this.$store.dispatch('player/playNow', {
          tracks: this.item.topTracks,
        })
      },
      shuffleNow() {
        return this.$store.dispatch('player/shuffleNow', {
          tracks: this.item.topTracks,
        })
      },
      toggleFavourite() {
        return this.favouriteStore.toggle('artist', this.id)
      },
      toggleAlbumSortOrder() {
        this.mainStore.toggleArtistAlbumSortOrder()
      },
    }
  })
</script>
